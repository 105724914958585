import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function AllTeam(props) {
  const slug = props.slug
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulTeamMember(
            sort: {fields: [firstName, lastName]}
          ) {
            nodes {
              contentful_id
              headshot {
                gatsbyImageData
              }
              name
              slug
            }
          }
        }
      `}
      render={data => (
        <>
          <div className="u__spacing">
            <h2 className="h1 text-center">Our Team</h2>
          </div>
          <div className="container u__spacing team py-0">
            <div className="row justify-content-center">
              {data.allContentfulTeamMember.nodes
                .filter(node => slug !== node.slug)
                .map(node => {
                  const headshot = getImage(node.headshot)
                  return (
                    <div className="team__member col-6 col-md-4 col-lg-3 u__vspacing-b" key={node.contentful_id}>
                      <Link to={`/about/team/${node.slug}`} className="team__link">
                        <div className="team__photo">
                          <GatsbyImage
                            image={headshot}
                            alt={`${node.name} headshot`}
                            aria-hidden="true"
                          />
                        </div>
                        <p>
                          <span className="team__name h5">{node.name}</span>
                        </p>
                      </Link>
                    </div>
                  )
              })}
            </div>
          </div>
        </>
      )}
    />
  )
}
