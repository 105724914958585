import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/layout/Layout"
import GetUpdates from "../components/GetUpdates"
import AllTeam from "../components/AllTeam"

const TeamMemberTemplate = ({ data }) => {
  const { name, website, linkedIn, twitter, gitHub, slug } = data.contentfulTeamMember
  const positions = data.contentfulTeamMember.position
  const headshot = getImage(data.contentfulTeamMember.headshot)
  const bio = data.contentfulTeamMember.body.childMarkdownRemark.html
  const excerpt = data.contentfulTeamMember.body.childMarkdownRemark.excerpt
  return (
    <>
      <Seo title={name} bodyClass="layout__team page__team presentation" description={excerpt} article={true} />
      <Layout>
        <section id="content" className="layout__main team">
          <div className="team__member__profile">
            <header className="team__member__header u__spacing s__light-splatter s__lil-splatter">
              <div className="row u__container">
                <div className="col-sm-6 team__member__name">
                  <h1 className="team__member__title display-5 pt-4 mb-2">{name}</h1>
                  {positions.map((position, index) => (
                    <p key={index} className="team__member__position h4"><b>{position.title}</b></p>
                  ))}
                  <div className="team__member__meta">
                  {website &&
                    <a className="team__member__social" href={website} target="_blank" rel="nofollow noreferrer" title={`Visit ${name}’s personal website in a new window`}>
                      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 64 64">
                        <path d="M32 31c6.6 0 12-5.4 12-12S38.6 7 32 7s-12 5.4-12 12 5.4 12 12 12zm-5.5-15c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5c0 2.2-1.3 4-3.1 4.9 2.2.8 4.1 2.5 4.1 4.8 0 2.1-3.4 3.4-6.5 3.4s-6.5-1.3-6.5-3.4c0-2.3 1.9-4 4.1-4.8-1.8-.9-3.1-2.7-3.1-4.9z"/>
                        <path d="M32 0C14.3 0 0 14.3 0 32s14.3 32 32 32 32-14.3 32-32S49.7 0 32 0zm0 5c7.7 0 14 6.3 14 14s-6.3 14-14 14-14-6.3-14-14S24.3 5 32 5zM16 37h32v4H16v-4zm29 18H19v-4h26v4zm5-7H14v-4h36v4z"/>
                      </svg>
                      <span className="sr-only">Personal website</span>
                    </a>
                  }
                  {linkedIn &&
                    <a className="team__member__social" href={`https://www.linkedin.com/in/${linkedIn}`} target="_blank" rel="nofollow noreferrer" title={`Visit ${name}’s LinkedIn profile in a new window`}>
                      <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/>
                      </svg>
                      <span className="sr-only">LinkedIn profile</span>
                    </a>
                  }
                  {twitter &&
                    <a className="team__member__social" href={`https://twitter.com/${twitter}`} target="_blank" rel="nofollow noreferrer" title={`Visit ${name}’s Twitter profile in a new window`}>
                      <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/>
                      </svg>
                      <span className="sr-only">Twitter profile</span>
                    </a>
                  }
                  {gitHub &&
                    <a className="team__member__social" href={`https://github.com/${gitHub}`} target="_blank" rel="nofollow noreferrer" title={`Visit ${name}’s GitHub profile in a new window`}>
                      <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                      </svg>
                      <span className="sr-only">Github profile</span>
                    </a>
                  }
                  </div>
                </div>
                <div className="col-sm-6 team__member__headshot">
                  <GatsbyImage
                    image={headshot}
                    alt={`${name} headshot`}
                  />
                </div>
              </div>
            </header>
            <article className="container u__container u__vspacing team__member__bio">
              <div className="row">
                <div className="col-sm-9 col-lg-8" dangerouslySetInnerHTML={{__html: bio}}></div>
              </div>
            </article>
          </div>
          <footer className="u__vspacing s__primary">
            <div className="u__container">
              <AllTeam slug={slug} />
            </div>
          </footer>
        </section>
        <GetUpdates />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query TeamMemberQuery($slug: String!) {
    contentfulTeamMember(slug: { eq: $slug }) {
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
      gitHub
      headshot {
        gatsbyImageData(width: 600)
      }
      linkedIn
      name
      position {
        title
      }
      slug
      twitter
      website
    }
  }
`

export default TeamMemberTemplate
